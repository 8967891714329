import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider';

const RESOURCE_URL = '/management_sessions';
const RESOURCE_CACHE_KEY = 'management_sessions';

export type ManagementSession = {
  id: string;
  realm: string;
  url: string;
  ip_address: string;
  return_url: string;
  expires_at: string;
  active: boolean;
  created_at: string;
  updated_at: string;
};

type ResultData = ManagementSession[];

const TEST_DATA = [
  {
    'id': 'ksuid1983747d',
    'realm': '1',
    'url': 'https://test-url.nrmitchi.com/ms_ksuid1983747d',
    'ip_address': '127.0.0.1',
    'return_url': 'https://return.nrmitchi.com/',
    'expires_at': '2020-10-10',
    'active': false,
    'created_at': '2020-10-10',
    'updated_at': '2020-10-10',
  },
  {
    'id': 'ksuid1983747d2342',
    'realm': '2',
    'url': 'https://test-url.nrmitchi.com/ms_ksuid1983747d2342',
    'ip_address': '127.0.0.1',
    'return_url': 'https://return.nrmitchi.com/',
    'expires_at': '2020-10-10',
    'active': true,
    'created_at': '2020-10-10',
    'updated_at': '2020-10-10',
  },
]

export const useManagementSession = (id: string) => {
  return useQuery<ManagementSession>([RESOURCE_CACHE_KEY, id], async (queryKey: QueryFunctionContext): Promise<ManagementSession> => {
    // const { client } = useAxios();
    // console.log(client)
    // return TEST_DATA[0]

    const { data } = await axios.get(`${RESOURCE_URL}/${id}`);
    return data;
  });
};

export const useManagementSessions = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    // const { client } = useAxios();
    // console.log(client)
    // return TEST_DATA
    const { data } = await axios.get(RESOURCE_URL);
    return data;
  });
};

export type AccessLog = {
  id: string
  ip_address: string
  access_type: string
  created_at: string
}

export const useManagementSessionAccessLogs = (id:string) => {
  return useQuery<AccessLog[]>([RESOURCE_CACHE_KEY, id, 'logs'], async (queryKey: QueryFunctionContext): Promise<AccessLog[]> => {
    // const { client } = useAxios();
    // console.log(client)
    // return TEST_DATA
    const { data } = await axios.get(`${RESOURCE_URL}/${id}/logs`);
    return data;
  });
};
