import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider'

const RESOURCE_URL = '/organizations/tokens';
const RESOURCE_CACHE_KEY = 'tokens';

export type APIToken = {
  id: number;
  service: string;
  key: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
};

type ResultData = APIToken[];

// simple axios async call
// export const useMembersQuery = async (queryKey: string): Promise<ResultData> => {
//   const { data } = await axios.get(RESOURCE_URL);
//   return data;
// };

// export const useMembers = () => useQuery<ResultData>(RESOURCE_CACHE_KEY, useMembersQuery);

export const getAPITokens = (client: AxiosInstance) => {
  return async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    const { data } = await client.get(RESOURCE_URL);
    return data;
  }
}

export const useAPITokens = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, getAPITokens(axios));
};
