import axios from 'axios'

import React, { Fragment } from 'react';

import { useTable, Column, useSortBy } from 'react-table'

import moment from 'moment';

import { Link } from 'react-router-dom'

import { Event } from '../../resources/useEvents';
import { Subscription } from '../../resources/useSubscriptions';

type Props = {
  events: Event[];
};

const formatTime = (t: string) => (t ? moment(t).format('L LT') : '');

const EventsListTable: React.FC<Props> = ({ events }) => {

  const AsBadge = ({ value }: { value: string }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <span className="badge badge-light" style={{ margin: '5px' }}>
        {value}
      </span>
    );
  };

  const EventOptionsDropdown = ({ value }: { value: string }) => {
    return (
      <div className="dropdown">
        <a
          className="dropdown-ellipses dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fe fe-more-vertical"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a href="#" className="dropdown-item">
            Option 1
          </a>
          <a href="#" className="dropdown-item">
            Option 2
          </a>
        </div>
      </div>
    );
  };

  const columns: Array<Column<Event>> = React.useMemo(
    () => [
      {
        // Build our selector/expander column
        id: 'selector', // Make sure it has an ID
      },
      {
        Header: 'ID',
        id: 'id',
        accessor: 'id',
      // There appears to be a Typing bug here. If accessor is a function (even with a return type specified), the row/value children of 'cell' do not have associated types
        Cell: ({ cell: { row, value } }) => <Link to={`/events/${value}`}>{value}</Link>,
      },
      {
        Header: 'Realm',
        accessor: 'realm',
        Cell: ({ cell: { value } }) => <AsBadge value={value}/>,
      },
      {
        Header: 'Key',
        accessor: 'key',
        Cell: ({ cell: { value } }) => <AsBadge value={value}/>,
        // getProps: (cell) => ({ id: () => alert("clicked")})
      },
      {
        Header: 'Timestamp',
        accessor: 'created_at',
        Cell: ({ cell: { value } }) => String(formatTime(value)),
        // getProps: (cell) => ({ id: () => alert("clicked")})
      },
      {
        // Build our options column
        id: 'options', // Make sure it has an ID
        // Cell: ({ cell: { row } }) => <EventOptionsDropdown />
      },
    ],
    []
  )

  const data = React.useMemo(
    () => events,
    [events]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Event>(
    { columns, data },
    useSortBy
  )

  return (
    <div className="table-responsive">
     <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render('Header')}
                 <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()} className="list">
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
    </div>
  );
};

const EventsListSearch = () => (
  <form>
    <div className="input-group input-group-flush">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="fe fe-search"></i>
        </span>
      </div>
      <input className="list-search form-control" type="search" placeholder="Search" />
    </div>
  </form>
);

const EventsListFilterDropdown: React.FC = () => (
  <div className="dropdown">
    <button
      className="btn btn-sm btn-white"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i className="fe fe-sliders mr-1"></i> Filter <span className="badge badge-primary ml-1 d-none">0</span>
    </button>

    <form className="dropdown-menu dropdown-menu-right dropdown-menu-card">
      <div className="card-header">
        <h4 className="card-header-title">Filters</h4>

        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
          <small>Clear filters</small>
        </button>
      </div>
      <div className="card-body">
        <div className="list-group list-group-flush mt-n4 mb-4">
          <div className="list-group-item">
            <div className="row">
              <div className="col">
                <small>Realm</small>
              </div>
              <div className="col-auto">
                <select
                  className="custom-select custom-select-sm"
                  name="item-title"
                  data-toggle="select"
                  data-options='{"minimumResultsForSearch": -1}'
                >
                  <option value="*" selected>
                    Any
                  </option>
                  <option value="Designer">Designer</option>
                  <option value="Developer">Developer</option>
                  <option value="Owner">Owner</option>
                  <option value="Founder">Founder</option>
                </select>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="row">
              <div className="col">
                <small>Key</small>
              </div>
              <div className="col-auto">
                <select
                  className="custom-select custom-select-sm"
                  name="item-score"
                  data-toggle="select"
                  data-options='{"minimumResultsForSearch": -1}'
                >
                  <option value="*" selected>
                    Any
                  </option>
                  <option value="1/10">1+</option>
                  <option value="2/10">2+</option>
                  <option value="3/10">3+</option>
                  <option value="4/10">4+</option>
                  <option value="5/10">5+</option>
                  <option value="6/10">6+</option>
                  <option value="7/10">7+</option>
                  <option value="8/10">8+</option>
                  <option value="9/10">9+</option>
                  <option value="10/10">10</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <button className="btn btn-block btn-primary" type="submit">
          Apply filter
        </button>
      </div>
    </form>
  </div>
);

const EventsListEmpty = () => {
  return (
    <div className="row">
      <div className="col-12">
        {/* <!-- Card --> */}
        <div className="card card-inactive">
          <div className="card-body text-center">
            {/* <!-- Image --> */}
            <img src="/assets/img/illustrations/scale.svg" alt="..." className="img-fluid" style={{ maxWidth: '182px' }} />

            {/* <!-- Title --> */}
            <h1>No Event yet.</h1>
            {/* <!-- Subtitle --> */}
            <p className="text-muted">Create a Event from your app with our API.</p>

          </div>
        </div>
      </div>
    </div>
  )
};

const EventsList: React.FC<Props> = ({ events }) => {

  return (
    <>
      {/* <div className="tab-content"> */}
      <div
        className="tab-pane fade show active"
        id="eventsListPane"
        role="tabpanel"
        aria-labelledby="eventsListTab"
      >
        <div
          className="card"
          id="eventsList"
        >
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <EventsListSearch />
              </div>
              <div className="col-auto">
                <EventsListFilterDropdown />
                {/* <CreateTestEventModal /> */}
              </div>
            </div>
          </div>

          <EventsListTable events={events} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export { EventsList, EventsListEmpty };
