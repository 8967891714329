import React from 'react';

const NotFound = () => (
  <div className="row">
    <div className="col-12">
      <div className="card card-inactive">
        <div className="card-body text-center">
          <img src="/assets/img/illustrations/empty.svg" alt="..." className="img-fluid" style={{ maxWidth: '182px' }} />
          <h1>We can't see to find what you're looking for...</h1>
          <p className="text-muted">
            Pick a page from the sidebar to explore your Account.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export { NotFound };
