import React, { useState, useEffect, useContext } from 'react';

import { useOrganizations, Organization } from '../resources/useOrganizations';

export const OrganizationContext = React.createContext<OrganizationContextType>({} as any);
export const useOrganization = () => useContext(OrganizationContext);

export const OrganizationProvider = ({ children, ...initOptions }: any) => {
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [currentOrganization, setCurrentOrganization] = useState<Organization>();

  const { isLoading, isSuccess, data = [] } = useOrganizations(); // http call to backend

  let persistedOrgJson = localStorage.getItem('currentOrgJson')
  if (persistedOrgJson == 'undefined' ) {
    persistedOrgJson = null
  }
  const persistedOrg: Organization = persistedOrgJson ? JSON.parse(persistedOrgJson) : null
  if (!currentOrganization && persistedOrg) {
    setCurrentOrganization(persistedOrg)
  }

  useEffect(() => {
    const initOrganizations = async () => {
      if (isSuccess) {
        setOrganizations(data);

        if (data.length == 0) {
          // We have no organizations that we have access to.
          console.log("User does not have access to any organizations.")
          setCurrentOrganization(undefined)
        } else if (!currentOrganization) {
          // If we have already explicitly selected a currentOrganization, we do not want to overwrite that.
          // Therefore, we only set an arbitrary organization if we have no persisted org.
          console.log(`Setting current organization to ${data[0].id}`)
          setAndPersistCurrentOrganization(data[0]);
        } else if (currentOrganization) {
          // If we have already explicitly selected a currentOrganization, we want to verify that it is in the list
          // of valid orgs that the user has access too. If it does not, reset to the first returned org.
          let is_valid = false
          data.forEach((org: Organization) => {
            if (org.id === currentOrganization.id) {
              // console.log(`currentOrg persisted (${currentOrganization.id}), match founds in returned org list`)
              setCurrentOrganization(org)
              is_valid = true
            }
          })

          if (!is_valid) {
            // console.log(`currentOrg persisted (${currentOrganization}), but org not in returned org list (${data.map((o: Organization) => o.id)})`)
            setAndPersistCurrentOrganization(data[0]);
          }
        }
      }
    };
    initOrganizations();
    // eslint-disable-next-line
  }, [isLoading, isSuccess, data]);

  const setAndPersistCurrentOrganization = (org: Organization) => {
    localStorage.setItem('currentOrgJson', JSON.stringify(org))
    setCurrentOrganization(org)
  }

  return (
    <OrganizationContext.Provider
      value={{
        currentOrganization,
        isLoading,
        isSuccess,
        organizations,
        setOrganization: setAndPersistCurrentOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

type OrganizationContextType = {
  currentOrganization?: Organization;
  organizations?: Organization[];
  isLoading: boolean;
  isSuccess: boolean;
  setOrganization: (o: Organization) => void;
};
