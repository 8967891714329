import axios, { AxiosInstance } from 'axios'

import React, { useState, useEffect, useContext } from 'react';

import { useOrganization } from './OrganizationProvider';
import { useAccessToken } from '../resources/useAccessToken';

// Set the default axios base URL
axios.defaults.baseURL = window._jsenv && window._jsenv.REACT_APP_API_HOST! || process.env.REACT_APP_API_HOST;
axios.defaults.timeout = 10000;

type AxiosContextType = {
  client: AxiosInstance;
};

export const AxiosContext = React.createContext<AxiosContextType>({} as any);
export const useAxios = () => useContext(AxiosContext);

export const AxiosProvider = ({ children }: { children: JSX.Element }) => {

  // // Create state with an initial client with no auth info
  const [client, setClient] = useState<AxiosInstance>(axios)

  const { token, setToken } = useAccessToken();
  const { currentOrganization } = useOrganization();

  // useEffect(() => {
  //   const orgName = currentOrganization ? currentOrganization.name : 'unknown'
  //   console.log(`Recreating axios client ${orgName}/${token}`)

  //   setClient(axios.create({
  //     baseURL: window._jsenv && window._jsenv.REACT_APP_API_HOST! || process.env.REACT_APP_API_HOST,
  //     timeout: 1000,
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'X-Organization': orgName,
  //     }
  //   }))
  // }, [token, currentOrganization])

  // Add Interceptor to retry Unauthorized requests (for when Auth0 token is expired)
  // # Todo (nrmitchi): Set a max number of retry attempts this will make.
  // axios.interceptors.response.use(response => response, error => {
  //   const status = error.response ? error.response.status : null
  //   if (status === 401) {
  //     return getAccessTokenSilently().then(token => {
  //       setToken(token)
  //       error.config.headers['Authorization'] = `Bearer ${token}`
  //       return axios.request(error.config);
  //     });
  //   } else {
  //     return Promise.reject(error);
  //   }
  // });

  useEffect(() => {

    // Don't set an interceptor if we don't have a token`
    if (!token) {
      return
    }

    const authInterceptorId = axios.interceptors.request.use(config => {
      config.headers['Authorization'] = `Bearer ${token}`
      return config
    }, error => {
      // handle the error
      console.log(error)
      return Promise.reject(error);
    })

    // Return a cleanup function to remove this interceptor before re-render
    return () => {
      axios.interceptors.request.eject(authInterceptorId);
    }
  }, [token])

  useEffect(() => {

    const orgString = currentOrganization ? currentOrganization.id : 'unknown';

    const authInterceptorId = axios.interceptors.request.use(config => {
      config.headers['X-Organization'] = orgString
      return config
    }, error => {
      // handle the error
      return Promise.reject(error);
    })

    // Return a cleanup function to remove this interceptor before re-render
    return () => {
      axios.interceptors.request.eject(authInterceptorId);
    }
  }, [currentOrganization])

  return <AxiosContext.Provider value={{ client }}>{children}</AxiosContext.Provider>;
};
