import React from 'react';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { UnderConstruction } from '../../components/UnderConstruction'


const Dashboard = () => (
  <>
    <PageHeader title="Dashboard" />
    <UnderConstruction />
  </>
);

export { Dashboard };
