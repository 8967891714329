import React from 'react';

type Props = {
  title: string | JSX.Element;
  sup_title?: string | JSX.Element;
  tabs?: JSX.Element;
  actionButton?: JSX.Element;
};

const PageHeader: React.FC<Props> = ({ title, sup_title, tabs, actionButton }) => (
  <div className="header">
    <div className="header-body">
      <div className="row align-items-end">
        <div className="col">
          <h6 className="header-pretitle">{sup_title || "Overview"}</h6>
          <h1 className="header-title">{title}</h1>
        </div>
        {actionButton && (
          <div className="col-auto">
            {/* <a href="#!" className="btn btn-primary lift">
              Action Button
            </a> */}
            {actionButton}
          </div>
        )}
      </div>
      {tabs && (
        <div className="row align-items-center">
          <div className="col">{tabs}</div>
        </div>
      )}
    </div>
  </div>
);

export { PageHeader };
