import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider';

const RESOURCE_URL = '/events';
const RESOURCE_CACHE_KEY = 'events';

export type Event = {
  id: string;
  realm: string;
  key: string;
  payload: object;  // This will be wrong if it ends up being possibly just an encrypted blob. I may have to use a sentinel object to represent that.
  created_at: string;
};

type ResultData = Event[];

const TEST_DATA = [
  {
    'id': 'ksuid1983747d444',
    'realm': '1',
    'key': 'customer.created',
    'payload': '{}',
    'created_at': '2020-10-10',
  },
  {
    'id': 'ksuid1sdf882lkdaaadf',
    'realm': '2',
    'key': 'invoices.created',
    'payload': '{}',
    'created_at': '2020-10-10',
  }
]

export const useEvent = (id: string) => {
  return useQuery<Event>([RESOURCE_CACHE_KEY, id], async (queryKey: QueryFunctionContext): Promise<Event> => {
    const { data } = await axios.get(`${RESOURCE_URL}/${id}`);
    return data;
  });
};

export const useEvents = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    const { data } = await axios.get(RESOURCE_URL);
    return data;
  });
};
