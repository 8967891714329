import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider';

const RESOURCE_URL = '/subscriptions';
const RESOURCE_CACHE_KEY = 'subscriptions';

export type Subscription = {
  id: string;
  realm: string;
  name: string;
  description: string;
  keys: string[];
  url: string;
  enabled: boolean;
  verified: boolean;
  success_rate: string;
  created_at: string;
  updated_at: string;
};

type ResultData = Subscription[];

const TEST_DATA = [
  {
    'id': 'ksuid1983747d',
    'name': '',
    'description': 'Webhook for sending to Slack',
    'realm': '1',
    'keys': ['customer.created','customer.updated','subscription.*'],
    'url': 'https://api.hookshothq.com/callback',
    'enabled': true,
    'success_rate': '0.65',
    'created_at': '2020-10-10',
    'updated_at': '2020-10-10',
  },
  {
    'id': 'ksuid1sdf882lkda',
    'name': 'test-2',
    'description': 'Hook to connect in to Salesforce',
    'realm': '2',
    'keys': ['invoices.*'],
    'url': 'https://api.previewhq.com/webhooks',
    'enabled': false,
    'success_rate': '0.998',
    'created_at': '2020-10-10',
    'updated_at': '2020-12-10',
  }
]

export const useSubscription = (id: string) => {
  return useQuery<Subscription>([RESOURCE_CACHE_KEY, id], async (queryKey: QueryFunctionContext): Promise<Subscription> => {
    // const { client } = useAxios();
    // console.log(client)
    // return TEST_DATA[0]

    const { data } = await axios.get(`${RESOURCE_URL}/${id}`);
    return data;
  });
};

export const useSubscriptions = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    // const { client } = useAxios();
    // console.log(client)
    // return TEST_DATA
    const { data } = await axios.get(RESOURCE_URL);
    return data;
  });
};
