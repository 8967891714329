import React from 'react';

import { useManagementSessions } from '../../resources/useManagementSessions';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ManagementSessionList } from '../../components/ManagementSessionList/ManagementSessionList';

import { ManagementSessionListEmpty } from '../../components/ManagementSessionList/ManagementSessionList';
import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

const ManagementSessions: React.FC = () => {
  const { isIdle, isLoading, isError, data: managementSessions = [] } = useManagementSessions(); // pull this out to container?

  return (
    <>
      <PageHeader title="Management Sessions" />
      {isIdle || isLoading
        ? <Loading />
        : isError
          ? <Error />
          : !managementSessions || managementSessions.length === 0
            ? <ManagementSessionListEmpty />
            : <ManagementSessionList managementSessions={managementSessions} />
      }
    </>
  )

};

export { ManagementSessions };
