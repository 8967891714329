import React from "react";

import { UserButton, useUser } from "@clerk/clerk-react";

import { useLocation, Link } from 'react-router-dom'

import { Loading } from '../Loading/Loading';

import { OrganizationSwitcher } from '../OrganizationSwitcher/OrganizationSwitcher';

import { useOrganization } from '../../providers/OrganizationProvider';

import logo from "./logo.png";


const Sidebar = () => {
  const location = useLocation();

  const { isLoading, currentOrganization } = useOrganization();

  return (
    <div className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light">
      <div className="container-fluid">
        {/* <!-- Toggler --> */}
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* <!-- Brand --> */}
        <Link to="/" className="navbar-brand">
          <img src={logo} className="navbar-brand-img mx-auto" alt="..." />
        </Link>

        {/* <!-- User (xs) --> */}
        <div className="navbar-user d-md-none">
          <UserButton />
        </div>

        {/* <!-- Collapse --> */}
        <div className="collapse navbar-collapse" id="sidebarCollapse">
          {/* <!-- Form --> */}
          <form className="mt-4 mb-3 d-md-none">
            <div className="input-group input-group-rounded input-group-merge">
              <input type="search" className="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search" />
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span className="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>

          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" role="button" className={`nav-link ${
                location.pathname == "/" ? "active" : ""
              }`}>
                <i className="fe fe-home"></i> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/subscriptions" role="button" className={`nav-link ${
                location.pathname.startsWith("/subscriptions") ? "active" : ""
              }`}>
                <i className="fe fe-clipboard"></i> Subscriptions
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/events" role="button" className={`nav-link ${
                location.pathname.startsWith("/events") ? "active" : ""
              }`}>
                <i className="fe fe-clipboard"></i> Events
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/sessions" role="button" className={`nav-link ${
                location.pathname.startsWith("/sessions") ? "active" : ""
              }`}>
                <i className="fe fe-cloud"></i> Management Sessions
              </Link>
            </li>
          </ul>

          <hr className="navbar-divider my-3" />

          {isLoading
            ? <Loading />  // Todo: Maybe make this a Skeleton instead of a loading circle
            : currentOrganization?.role == "admin" &&
              <>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    href="#sidebarOrganization"
                    className="nav-link"
                    data-toggle="collapse"
                    role="button"
                    aria-expanded={location.pathname.startsWith("/organization") ? "true" : "false"}
                    aria-controls="sidebarOrganization"
                  >
                    <i className="fe fe-users"></i> Organization
                  </a>
                  <div
                    className={`collapse ${
                      location.pathname.startsWith("/organization") ? "show" : ""
                    }`}
                    id="sidebarOrganization"
                  >
                    <ul className="nav nav-sm flex-column">
                      {/* <li className="nav-item">
                        <Link
                          to="/organization/general"
                          className={`nav-link ${
                            location.pathname.startsWith("/organization/general") ? "active" : ""
                          }`}
                        >
                          General
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          to="/organization/security"
                          className={`nav-link ${
                            location.pathname.startsWith("/organization/security") ? "active" : ""
                          }`}
                        >
                          Security <span className="badge badge-soft-info ml-2">Beta</span>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          to="/organization/members"
                          className={`nav-link ${
                            location.pathname.startsWith("/organization/members") ? "active" : ""
                          }`}
                        >
                          Members <span className="badge badge-soft-info ml-2">Beta</span>
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          to="/organization/billing"
                          className={`nav-link ${
                            location.pathname.startsWith("/organization/billing") ? "active" : ""
                          }`}
                        >
                          Billing <span className="badge badge-soft-info ml-2">Beta</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/organization/notifications"
                          className={`nav-link ${
                            location.pathname.startsWith("/organization/notifications") ? "active" : ""
                          }`}
                        >
                          Notifications <span className="badge badge-soft-info ml-2">Beta</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <hr className="navbar-divider my-3" />
              </>
          }

          <OrganizationSwitcher />
          <div className="mt-auto"></div>

          <div id="sidebarUser">
            <UserButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
