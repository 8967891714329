import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { Sidebar } from './components/Sidebar/Sidebar';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Subscriptions } from './pages/Subscriptions/Subscriptions';
import { SubscriptionDetailsPage } from './pages/SubscriptionDetails/SubscriptionDetails';
import { Events } from './pages/Events/Events';
import { ManagementSessions } from './pages/ManagementSessions/ManagementSessions';
import { ManagementSessionDetailsPage } from './pages/ManagementSessionDetails/ManagementSessionDetails';
import { EventDetailsPage } from './pages/EventDetails/EventDetails';
import { OrganizationBilling } from './pages/OrganizationBilling/OrganizationBilling';
import { OrganizationMembers } from './pages/OrganizationMembers/OrganizationMembers';
import { OrganizationSecurity } from './pages/OrganizationSecurity/OrganizationSecurity';
import { OrganizationNotifications } from './pages/OrganizationNotifications/OrganizationNotifications';

import history from './utils/history';
import './theme/theme.scss';

const App = () => {

  return (
    <div className="App">
      <Router history={history}>
        <Sidebar />
        <div className="main-content">
          <Container fluid={true}>
            <Row>
              <Col>
                <Switch>
                  <Route path="/" exact component={Dashboard} />
                  <Route path="/subscriptions" exact component={Subscriptions} />
                  <Route path="/subscriptions/:id" component={SubscriptionDetailsPage} />
                  <Route path="/events" exact component={Events} />
                  <Route path="/events/:id" component={EventDetailsPage} />
                  <Route path="/sessions" exact component={ManagementSessions} />
                  <Route path="/sessions/:id" exact component={ManagementSessionDetailsPage} />
                  <Route path="/organization/billing" exact component={OrganizationBilling} />
                  <Route path="/organization/members" exact component={OrganizationMembers} />
                  <Route path="/organization/security" exact component={OrganizationSecurity} />
                  <Route path="/organization/notifications" exact component={OrganizationNotifications} />
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
      </Router>
    </div>
  );
};

export { App };
