
import axios from 'axios'
import React from 'react';

import { NotFound } from '../NotFound'

import { AccessLog, ManagementSession, useManagementSessionAccessLogs } from '../../resources/useManagementSessions';

import { Loading } from '../Loading/Loading';
import { Error } from '../Error/Error';

type Props = {
  managementSession?: ManagementSession;
};

const ManagementSessionCard: React.FC<Props> = ({ managementSession }) => {

  const { isIdle, isLoading, isError, error, data: subscriptionManagementSessions } = useManagementSessionAccessLogs(managementSession ? managementSession.id : '0'); // pull this out to container?

  if (!managementSession) {
    return <NotFound />
  }

  const AccessLogResultBadge = ({ accessLog }: { accessLog: AccessLog }) => {

    let badge = 'success'

    if (accessLog.access_type.startsWith('rejected')) {
      badge = 'danger'
    }
    return (
      <span className={`badge badge-${badge}`}>{accessLog.access_type}</span>
    )
  }
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card card-fill">
            <div className="card-header">
              <h4 className="card-header-title">
                Management Session Details
              </h4>
            </div>
            <div className="card-body">

              <div className="mb-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>URL</small> <a target="_blank" href={managementSession.url}>{managementSession.url}</a>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Realm</small> <span className="badge badge-light">{managementSession.realm}</span>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Return To</small> <a target="_blank" href={managementSession.return_url}>{managementSession.return_url}</a>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>IP Address</small> <span className="badge badge-light">{managementSession.ip_address}</span>
                  </li>
                </ul>
              </div>

            </div>
          </div>

        </div>
        <div className="col-12 col-xl-3">

        </div>
      </div>

      <div className="row">
        <div className="col-12">

          <div className="card card-fill">
            <div className="card-header">
              <h4 className="card-header-title">
                Access Logs
              </h4>
            </div>
            <div className="card-body">
              <div className="mb-3">
                {isIdle || isLoading
                  ? <Loading />
                  : isError
                    ? <Error />
                    : !subscriptionManagementSessions || subscriptionManagementSessions.length === 0
                      ? <NotFound />
                      : <ul className="list-group list-group-flush">
                      {subscriptionManagementSessions.map((al: AccessLog) => {
                        return (
                          <li key={al.id} className="list-group-item d-flex align-items-center justify-content-between px-0">
                            <small>{al.ip_address}</small> <small>{al.created_at}</small> <small><AccessLogResultBadge accessLog={al} /></small>
                          </li>
                        )
                      })}
                      </ul>
                }
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
};


export { ManagementSessionCard };
