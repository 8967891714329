
import axios from 'axios'
import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import ReactJson from 'react-json-view'

import { NotFound } from '../NotFound'
import { UnderConstruction } from '../UnderConstruction'

import { Event } from '../../resources/useEvents';
import { SubscriptionEvent, useSubscriptionEventsFromEvent } from '../../resources/useSubscriptionEvents';

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

import Skeleton from 'react-loading-skeleton';

type Props = {
  event?: Event;
};

const EventCard: React.FC<Props> = ({ event }) => {

  const { isIdle, isLoading, isError, error, data: subscriptionEvents } = useSubscriptionEventsFromEvent(event ? event.id : '0'); // pull this out to container?

  if (!event) {
    return <NotFound />
  }

  const StatusBadge = ( { subscriptionEvent }: { subscriptionEvent: SubscriptionEvent } ) => {

    let color = 'info'

    if (subscriptionEvent.status == 'success') {
      color = 'success'
    } else if (subscriptionEvent.status == 'failed') {
      color = 'danger'
    }
    return (
      <span className={`badge badge-${color}`}>
        {subscriptionEvent.status}
      </span>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card card-fill">
            <div className="card-header">
              <h4 className="card-header-title">
                Event Details
              </h4>
            </div>
            <div className="card-body">

              <div className="mb-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Type</small> <span className="badge badge-light">{event.key}</span>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Realm</small> <span className="badge badge-light">{event.realm}</span>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Matching Subscriptions</small> {isIdle || isLoading ? <Skeleton width={30} /> : <small>{subscriptionEvents?.length}</small>}
                  </li>
                </ul>
              </div>

            </div>
          </div>

        </div>
        <div className="col-12 col-xl-3">

        </div>
      </div>

      <div className="row">
        <div className="col-12">

          <div className="card card-fill">
            <div className="card-header">
              <h4 className="card-header-title">
                Subscriptions
              </h4>
            </div>
            <div className="card-body">
              <div className="mb-3">
                {isIdle || isLoading
                  ? <Loading />
                  : isError
                    ? <Error />
                    : !subscriptionEvents || subscriptionEvents.length === 0
                      ? <NotFound />
                      : <ul className="list-group list-group-flush">
                      {subscriptionEvents.map((se: SubscriptionEvent) => {
                        return (
                          <li key={se.id} className="list-group-item d-flex align-items-center justify-content-between px-0">
                            <small><Link to={`/subscriptions/${se.subscription_id}/events/${se.event_id}`}>{se.subscription?.name ? se.subscription.name : se.subscription_id}</Link></small> {se ? <StatusBadge subscriptionEvent={se} /> : <Skeleton width={50} />}
                          </li>
                        )
                      })}
                      </ul>
                }
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card card-fill">
            <div className="card-header">
              <h4 className="card-header-title">
                Payload
              </h4>
            </div>
            <div className="card-body">
              <ReactJson src={event.payload} />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};


export { EventCard };
