import React from 'react';
import { useParams, useLocation, useRouteMatch, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';

import { useManagementSession, ManagementSession } from '../../resources/useManagementSessions';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ManagementSessionCard } from '../../components/ManagementSessionCard/ManagementSessionCard'

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';
import { NotFound } from '../../components/NotFound';

interface RouteParams {
  id: string
}

// type ManagementSessionProps = {
//   event: ManagementSession
// }

interface RouteParams {
  id: string
}

const Overview: React.FC = () => {

  const params = useParams<RouteParams>();

  const { isIdle, isLoading, isError, error, data: managementSession } = useManagementSession(params.id); // pull this out to container?
  return (
    <>
    {isIdle || isLoading
      ? <Loading />
      : isError  // Todo (nrmitchi): This way of "Error handling" is _horrid_
        ? error && error.message == "Request failed with status code 404"
          ? <NotFound />
          : <Error />
        : <ManagementSessionCard managementSession={managementSession} />
    }
    </>
  )
}


const ManagementSessionDetailsPage: React.FC = () => {

  const params = useParams<RouteParams>();

  const { isIdle, isLoading, isError, error, data: managementSession } = useManagementSession(params.id); // pull this out to container?

  return (
    <>
      <PageHeader title={ managementSession ? `${managementSession.id}`: <Skeleton width={200} />} sup_title="ManagementSession" />
      <Overview />
    </>
  )

};

export { ManagementSessionDetailsPage };
