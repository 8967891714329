import React from 'react';

import { useSubscriptions } from '../../resources/useSubscriptions';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { SubscriptionsList } from '../../components/SubscriptionsList/SubscriptionsList';

import { SubscriptionsListEmpty } from '../../components/SubscriptionsList/SubscriptionsList';
import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

const Subscriptions: React.FC = () => {
  const { isIdle, isLoading, isError, data: subscriptions = [] } = useSubscriptions(); // pull this out to container?

  return (
    <>
      <PageHeader title="Subscriptions" />
      {isIdle || isLoading
        ? <Loading />
        : isError
          ? <Error />
          : !subscriptions || subscriptions.length === 0
            ? <SubscriptionsListEmpty />
            : <SubscriptionsList subscriptions={subscriptions} />
      }
    </>
  )

};

export { Subscriptions };
