import React from 'react';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { APITokensList } from '../../components/APITokenList/APITokenList'

const OrganizationSecurity: React.FC = () => {

  return (
    <>
      <PageHeader title="Security" sup_title="Organization" />
      <div className="row justify-content-between">
        <div className="col-12 col-md-12">
          <APITokensList />
        </div>
      </div>
    </>
  );
}

export { OrganizationSecurity };
