import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query'

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import {
  useClerk,
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
  SignIn,
  SignUp
} from "@clerk/clerk-react";
import { ToastProvider } from 'react-toast-notifications';

import { OrganizationProvider } from './providers/OrganizationProvider'
import { AxiosProvider } from './providers/AxiosProvider'

import history from './utils/history';

const frontendClerkApi = window._jsenv && window._jsenv.REACT_APP_CLERK_FRONTEND_API! || process.env.REACT_APP_CLERK_FRONTEND_API!;

const queryClient = new QueryClient()

// Let me extend the window object to support runtime injection
declare global {
  interface Window {
      _jsenv:any;
  }
}


const onRedirectCallback = (appState: any) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const SignInButton = () => {
  const { openSignIn } = useClerk();
  openSignIn({redirectUrl: 'http://localhost:3000'})
  return <></>
};

ReactDOM.render(
  <React.StrictMode>
    <ClerkProvider frontendApi={frontendClerkApi}>
      <SignedIn>
        <QueryClientProvider client={queryClient}>
          <OrganizationProvider>
            <ToastProvider>
              <AxiosProvider>
                <App />
              </AxiosProvider>
            </ToastProvider>
          </OrganizationProvider>
        </QueryClientProvider>
      </SignedIn>
      <SignedOut>
        <div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
          <SignIn redirectUrl={window.location.href} />
        </div>
      </SignedOut>
    </ClerkProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
