import React from "react";

import { Link } from 'react-router-dom'

import { Modal } from 'react-bootstrap'

import { Loading } from '../Loading/Loading';

import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from "../../resources/useOrganizations";

type OrganizationSwitcherModalProps = {
  show: boolean;
  onHide: () => void;
  onSelect: (o: Organization) => void;
}

const OrganizationSwitcherModal = (props: OrganizationSwitcherModalProps) => {
  const { currentOrganization, organizations, isLoading, isSuccess, setOrganization } = useOrganization()

  // Todo (nrmitchi): Include the organization icon from Github
  return (
    <>
    {!isSuccess || !currentOrganization
      ? <Loading />
      : (<Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Organization
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul className="list-group list-group-flush list my-n3">
            {isLoading
              ? <Loading />
              : organizations && organizations.map((o) => {
                  return (
                    <li key={o.id} className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <span className="avatar">
                            {o.avatar
                              ? <img src={o.avatar} alt="..." className="avatar-img rounded-circle" />
                              : <span className="avatar-title rounded-circle">{o.name.charAt(0).toUpperCase()}</span>
                            }
                          </span>
                        </div>
                        <div className="col ml-n2">
                          <h4 className="mb-1 name">
                          <span>{o.name}</span> {currentOrganization.id === o.id && <span className="badge badge-soft-info ml-2">Current</span>}
                          </h4>
                        </div>
                        <div className="col-auto">
                          {currentOrganization.id !== o.id && <button className="btn btn-sm btn-white" onClick={() => props.onSelect(o)}>Activate</button>}
                        </div>
                      </div>
                    </li>
                  )
                })
            }
            </ul>
          </Modal.Body>
        </Modal>)
    }
    </>
  );
}

const OrganizationSwitcher: React.FC<any> = (props: any) => {

  const { isLoading, currentOrganization, organizations = [], setOrganization } = useOrganization();
  const [modalShow, setModalShow] = React.useState(false);

  // Todo (nrmitchi): This loading state does not reflect the styling (size, etc) of the "true" element. This can be fixed, or replaced with a grey-"skeleton" box loading state
  function onSelect(o: Organization) {
    setOrganization(o);
    // Hide the Modal after switching Organization
    // Todo: Investigate why the animation (fade out) is not working
    setModalShow(false)
  }

  return (
    <>
    {isLoading
      ? (<>
        <li className="dropdown-item"><Loading /></li>
        <hr className="dropdown-divider" />
        </>)
      : organizations.length > 1
        ? (<>
          <Link to="#" className="dropdown-item" onClick={() => setModalShow(true)}>Switch Organization</Link>
          <OrganizationSwitcherModal show={modalShow} onHide={() => setModalShow(false)} onSelect={onSelect} />
          <hr className="dropdown-divider" />
          </>)
        : <></>
    }
    </>
  )
}

export { OrganizationSwitcher };
