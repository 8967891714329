import React from 'react';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { UnderConstruction } from '../../components/UnderConstruction'


const OrganizationNotifications: React.FC = () => {

  return (
    <>
      <PageHeader title="Notifications" sup_title="Organization" />
      <UnderConstruction />
    </>
  );
}

export { OrganizationNotifications };
