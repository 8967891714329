import axios from 'axios';

import React from 'react';
import { useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import { APIToken, useAPITokens } from '../../resources/useAPITokens';

import { Loading } from '../Loading/Loading';
import { Error } from '../Error/Error';


const APITokensList: React.FC = () => {
  const { isIdle, isLoading, isError, data: tokens = [] } = useAPITokens(); // pull this out to container?
  const { addToast } = useToasts()

  const createToken = useMutation( () => {
    return axios.post("/organizations/tokens", {})
      .then( res => {
        addToast(`API Token Created: ${res.data.id}`, {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        addToast(`API Token Creation Failed`, {
          appearance: 'error',
          autoDismiss: true,
        })
      });
  })

  const handleCreateToken = ( (event: any) => {
    event.preventDefault()
    createToken.mutate()
  })

  const removeToken = useMutation( ({ token }: {token: APIToken}) => {
    return axios.delete(`/organizations/tokens/${token.id}`, {})
      .then( res => {
        addToast(`Token Deleted`, {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        addToast(`Token Deletion Failed`, {
          appearance: 'error',
          autoDismiss: true,
        })
      });
  })

  const handleTokenRemoval = ((token: APIToken) => {
    return ((event: any) => {
      event.preventDefault()
      removeToken.mutate({token: token})
    })
  })

  const APITokensListEmpty = () => {
    return (
      <div className="row">
        <div className="col-12">
          {/* <!-- Card --> */}
          <div className="card card-inactive">
            <div className="card-body text-center">
              {/* <!-- Image --> */}
              <img src="/assets/img/illustrations/scale.svg" alt="..." className="img-fluid" style={{ maxWidth: '182px' }} />

              {/* <!-- Title --> */}
              <h1>No API Keys yet.</h1>
              {/* <!-- Subtitle --> */}
              <p className="text-muted">Create a Deployment by creating a Pull Request in Github, or manually below.</p>

              <button className="btn btn-sm btn-primary" onClick={handleCreateToken}>Create Token</button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">API Tokens <span className="badge badge-soft-info ml-2">Beta</span></h4>
        <button className="btn btn-sm btn-primary" onClick={handleCreateToken}>Create Token</button>
      </div>

      <div className="card-body">

        {isIdle || isLoading
          ? <Loading />
          : isError
            ? <Error />
            : !tokens || tokens.length == 0
              ? <APITokensListEmpty />
              : <div className="list-group list-group-flush my-n3">
                {tokens.map((t: APIToken) => {
                  return (
                    <div className="list-group-item" key={t.id}>
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 style={{ marginTop: '17px' }}>{t.key}</h3>
                        </div>
                        <div className="col-2">
                          <h3 style={{ marginTop: '17px' }}>{t.created_at}</h3>
                        </div>
                        <div className="col-auto ml-auto">
                          <a href="#!" onClick={handleTokenRemoval(t)} className="btn btn-sm btn-white">Remove</a>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
        }
      </div>
    </div>
  );
};

export { APITokensList };
