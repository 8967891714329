import { QueryKey, QueryFunction, useQuery as useReactQuery } from 'react-query';

import { useAccessToken } from './useAccessToken';
import { useAxios } from '../providers/AxiosProvider';

export const useQuery = <T>(
  queryKey: QueryKey,
  queryFn: QueryFunction,
) => {
  const token = useAccessToken();

  return useReactQuery(queryKey, queryFn, {
    enabled: !!token,
    cacheTime: 0,
    // refetchInterval: 10000,  // Temporarily disabled because 1) it is applying outside of the scope I want it to, and 2) it isn't causing a rerender anyways
    retry: (failureCount: any, e: any) => {
      console.log(e)
      // Auth0 is returning spurious 429's, which cause a 401. For now, we give some extra chances
      if (e.response?.status == 401) {
        return failureCount <= 3;
      } else if (e.response?.status < 500) {
        return false
      } else {
        return failureCount <= 3;
      }
    }, // Do not retry; This should retry on 5xx, but _not_ 4xx
  } as any) as any; // useQuery's typescript signatures are out of date
};
