import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider'

const RESOURCE_URL = '/dashboard/realms';
const RESOURCE_CACHE_KEY = 'realms';

export type Realm = string;

type ResultData = Realm[];

// simple axios async call
// export const useRealmQuery = async (queryKey: string): Promise<ResultData> => {
//   const { data } = await axios.get(RESOURCE_URL);
//   return data;
// };

// export const useRealm = () => useQuery<ResultData>(RESOURCE_CACHE_KEY, useRealmQuery);

export const getRealms = (client: AxiosInstance) => {
  return async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    const { data } = await client.get(RESOURCE_URL);
    return data;
  }
}

export const useRealms = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, getRealms(axios));
};
