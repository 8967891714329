import React from 'react';

import './Loading.scss';

const Loading = () => (
  <div className="loading-component main-content" style={{ padding: "20px" }}>
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export { Loading };
