import axios from 'axios'

import React, { Fragment } from 'react';

import { useMutation, useQueryClient } from 'react-query'
import { useForm, Controller } from 'react-hook-form';
import Select, { ValueType } from 'react-select'

import { useTable, Column, useSortBy } from 'react-table'

import { useToasts } from 'react-toast-notifications'

import moment from 'moment';

import { Link } from 'react-router-dom'
import { Modal, Form, Button } from 'react-bootstrap'

import { ManagementSession } from '../../resources/useManagementSessions';
import { Realm, useRealms } from '../../resources/useRealms';
import { Subscription } from '../../resources/useSubscriptions';

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

type Props = {
  managementSessions: ManagementSession[];
};

const formatTime = (t: string) => (t ? moment(t).format('L LT') : '');

const ManagementSessionListTable: React.FC<Props> = ({ managementSessions }) => {

  const AsBadge = ({ value }: { value: string }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <span className="badge badge-light" style={{ margin: '5px' }}>
        {value}
      </span>
    );
  };

  const AsBadgeSuccess = ({ value }: { value: string }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <span className="badge badge-success" style={{ margin: '5px' }}>
        {value}
      </span>
    );
  };

  const AsBadgeDanger = ({ value }: { value: string }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <span className="badge badge-danger" style={{ margin: '5px' }}>
        {value}
      </span>
    );
  };

  const AsExternalLink = ({ value }: { value: string }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <a target="_blank" href={value}>{value}</a>
    );
  };

  const ManagementSessionOptionsDropdown = ({ value }: { value: string }) => {
    return (
      <div className="dropdown">
        <a
          className="dropdown-ellipses dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fe fe-more-vertical"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a href="#" className="dropdown-item">
            Option 1
          </a>
          <a href="#" className="dropdown-item">
            Option 2
          </a>
        </div>
      </div>
    );
  };

  const columns: Array<Column<ManagementSession>> = React.useMemo(
    () => [
      {
        // Build our selector/expander column
        id: 'selector', // Make sure it has an ID
      },
      {
        Header: 'ID',
        id: 'id',
        accessor: 'id',
        Cell: ({ cell: { row, value } }) => <Link to={`/sessions/${value}`}>{value}</Link>,
      },
      {
        Header: 'Realm',
        accessor: 'realm',
        Cell: ({ cell: { value } }) => <AsBadge value={value}/>,
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ cell: { row, value } }) => row.original.active ? <AsExternalLink value={value}/> : <span>{value}</span>,
        // getProps: (cell) => ({ id: () => alert("clicked")})
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ cell: { row, value } }) => row.original.active ? <AsBadgeSuccess value="active" /> : <AsBadgeDanger value="expired" />,
        // getProps: (cell) => ({ id: () => alert("clicked")})
      },
      {
        Header: 'Expires At',
        accessor: 'expires_at',
        Cell: ({ cell: { value } }) => String(formatTime(value)),
        // getProps: (cell) => ({ id: () => alert("clicked")})
      },
      {
        // Build our options column
        id: 'options', // Make sure it has an ID
        // Cell: ({ cell: { row } }) => <ManagementSessionOptionsDropdown />
      },
    ],
    []
  )

  const data = React.useMemo(
    () => managementSessions,
    [managementSessions]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<ManagementSession>(
    { columns, data },
    useSortBy
  )

  return (
    <div className="table-responsive">
     <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render('Header')}
                 <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()} className="list">
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
    </div>
  );
};

const ManagementSessionListSearch = () => (
  <form>
    <div className="input-group input-group-flush">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="fe fe-search"></i>
        </span>
      </div>
      <input className="list-search form-control" type="search" placeholder="Search" />
    </div>
  </form>
);

const ManagementSessionListFilterDropdown: React.FC = () => (
  <div className="dropdown">
    <button
      className="btn btn-sm btn-white"
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i className="fe fe-sliders mr-1"></i> Filter <span className="badge badge-primary ml-1 d-none">0</span>
    </button>

    <form className="dropdown-menu dropdown-menu-right dropdown-menu-card">
      <div className="card-header">
        <h4 className="card-header-title">Filters</h4>

        <button className="btn btn-sm btn-link text-reset d-none" type="reset">
          <small>Clear filters</small>
        </button>
      </div>
      <div className="card-body">
        <div className="list-group list-group-flush mt-n4 mb-4">
          <div className="list-group-item">
            <div className="row">
              <div className="col">
                <small>Title</small>
              </div>
              <div className="col-auto">
                <select
                  className="custom-select custom-select-sm"
                  name="item-title"
                  data-toggle="select"
                  data-options='{"minimumResultsForSearch": -1}'
                >
                  <option value="*" selected>
                    Any
                  </option>
                  <option value="Designer">Designer</option>
                  <option value="Developer">Developer</option>
                  <option value="Owner">Owner</option>
                  <option value="Founder">Founder</option>
                </select>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="row">
              <div className="col">
                <small>Lead scrore</small>
              </div>
              <div className="col-auto">
                <select
                  className="custom-select custom-select-sm"
                  name="item-score"
                  data-toggle="select"
                  data-options='{"minimumResultsForSearch": -1}'
                >
                  <option value="*" selected>
                    Any
                  </option>
                  <option value="1/10">1+</option>
                  <option value="2/10">2+</option>
                  <option value="3/10">3+</option>
                  <option value="4/10">4+</option>
                  <option value="5/10">5+</option>
                  <option value="6/10">6+</option>
                  <option value="7/10">7+</option>
                  <option value="8/10">8+</option>
                  <option value="9/10">9+</option>
                  <option value="10/10">10</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <button className="btn btn-block btn-primary" type="submit">
          Apply filter
        </button>
      </div>
    </form>
  </div>
);

const ManagementSessionListEmpty = () => {
  return (
    <div className="row">
      <div className="col-12">
        {/* <!-- Card --> */}
        <div className="card card-inactive">
          <div className="card-body text-center">
            {/* <!-- Image --> */}
            <img src="/assets/img/illustrations/scale.svg" alt="..." className="img-fluid" style={{ maxWidth: '182px' }} />

            {/* <!-- Title --> */}
            <h1>No Management Sessions yet.</h1>
            {/* <!-- Subtitle --> */}
            <p className="text-muted">Create a ManagementSessions by creating a Pull Request in Github, or manually below.</p>

            <CreateManagementSessionsModal />
          </div>
        </div>
      </div>
    </div>
  )
};

const ManagementSessionList: React.FC<Props> = ({ managementSessions }) => {

  return (
    <>
      {/* <div className="tab-content"> */}
      <div
        className="tab-pane fade show active"
        id="managementSessionsListPane"
        role="tabpanel"
        aria-labelledby="managementSessionsListTab"
      >
        <div
          className="card"
          id="managementSessionsList"
        >
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <ManagementSessionListSearch />
              </div>
              <div className="col-auto">
                {/* <ManagementSessionListFilterDropdown /> */}
                <CreateManagementSessionsModal />
              </div>
            </div>
          </div>

          <ManagementSessionListTable managementSessions={managementSessions} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

const CreateManagementSessionsModal = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const { addToast } = useToasts()

  const { isIdle, isLoading, isError, data: eventTypes = [] } = useRealms(); // pull this out to container?

  type FormValues = {
    name: string;
    ip_address: string;
    realm: string;
  };
  const { register, handleSubmit, formState, control } = useForm<FormValues>();
  const { errors } = formState

  const queryClient = useQueryClient()

  const createManagementSession = useMutation( ({ name, ip_address, realm }: FormValues) => {
    return axios.post("/management_sessions", {
      name: name,
      ip_address: ip_address,
      realm: realm,
    })
    .then( res => {
      addToast(`Management Session Created: ${res.data.id}`, {
        appearance: 'success',
        autoDismiss: true,
      })
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      addToast(`Management Session Creation Failed`, {
        appearance: 'error',
        autoDismiss: true,
      })
    });
  }, {
    onSuccess: () => {
      console.log("invaliding management_sessions cache")
      queryClient.invalidateQueries('management_sessions')
      queryClient.refetchQueries('management_sessions')
      console.log("refetched management_sessions")
    }
  })

  const onSubmit = ( (data: FormValues) => {
    createManagementSession.mutateAsync(data).then( (res) => {
      // Todo: Only want this on mutate success
      setModalShow(false)
    })
  })

  // This is explicitly for react-select to play nicely (ish) with Typescript
  type OptionType = {label: string, value: string}

  return (
    <>
    <button className="btn btn-sm btn-white" onClick={() => setModalShow(true)}>Create Management Sessions</button>

    <Modal
      show={modalShow} onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Management Session
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>

          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="management session name" name="name" ref={register({maxLength: 80})} />
          </Form.Group>

          <Form.Group>
            <Form.Label>IP Address</Form.Label>
            <Form.Control type="text" placeholder="limit access to only this IP" name="ip_address" ref={register()} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Realm</Form.Label>
            {isIdle || isLoading
              ? <Loading />
              : isError
                ? <Error />
                : <Controller
                    control={control}
                    name="realm"
                    ref={register({required: true})}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    render={( { onChange } ) => (
                      <Select
                        onChange={(selectedOption: ValueType<OptionType, boolean>) => {
                          const value = (selectedOption as OptionType).value;

                          onChange(value)
                        }}
                        options={eventTypes.sort().map((r: Realm) => {
                          return { value: r, label: r }
                        })}
                      />
                      )}
                  />
            }
          </Form.Group>

          <input className="btn btn-primary" type="submit" />

        </Form>
      </Modal.Body>
    </Modal>
    </>
  );
}

export { ManagementSessionList, ManagementSessionListEmpty };
