
import React from 'react';
import { Link, useHistory } from 'react-router-dom'

import { useTable, Column, useSortBy } from 'react-table'

import { UnderConstruction } from '../../components/UnderConstruction'

import { Subscription } from '../../resources/useSubscriptions';
import { SubscriptionEvent, useSubscriptionEventsFromSubscription, WebhookAttempt } from '../../resources/useSubscriptionEvents';

import { NotFound } from '../../components/NotFound'
import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';


type Props = {
  subscription: Subscription
}

export const EventsList: React.FC<Props> = ({ subscription }) => {

  const { isIdle, isLoading, isError, error, data: subscriptionEvents } = useSubscriptionEventsFromSubscription(subscription.id); // pull this out to container?

  const StatusBadge = ( { subscriptionEvent }: { subscriptionEvent: SubscriptionEvent } ) => {

    let color = 'info'

    if (subscriptionEvent.status == 'success') {
      color = 'success'
    } else if (subscriptionEvent.status == 'failed') {
      color = 'danger'
    }
    return (
      <span className={`badge badge-${color}`} style={{ margin: '5px' }}>
        {subscriptionEvent.status}
      </span>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12">

          <div className="card card-fill">
            <div className="card-header">
              <h4 className="card-header-title">
                Events
              </h4>
            </div>

            {isIdle || isLoading
              ? <div className="card-body"><Loading /></div>
              : isError
                ? <div className="card-body"><Error /></div>
                : !subscriptionEvents || subscriptionEvents.length === 0
                  ? <div className="card-body"><NotFound /></div>
                  : <SubscriptionEventsTable subscriptionEvents={subscriptionEvents} />
            }

          </div>

        </div>
      </div>
    </>
  )
}

const SubscriptionEventsTable: React.FC<{subscriptionEvents: SubscriptionEvent[]}> = ({ subscriptionEvents }) => {

  const StatusBadge = ( { subscriptionEvent }: { subscriptionEvent: SubscriptionEvent } ) => {

    let color = 'info'

    if (subscriptionEvent.status == 'success') {
      color = 'success'
    } else if (subscriptionEvent.status == 'failed') {
      color = 'danger'
    }
    return (
      <span className={`badge badge-${color}`}>
        {subscriptionEvent.status}
      </span>
    );
  }

  const columns: Array<Column<SubscriptionEvent>> = React.useMemo(
    () => [
      {
        Header: 'Timestamp',
        accessor: 'created_at',
        // Cell: ({ cell: { value } }) => <SuccessRateBadge value={value} />,
      },
      {
        Header: 'Type',
        accessor: row => row.event.key,
        Cell: ({ cell: { value } }: {cell:{value: string}}) => <span className="badge badge-light">{value}</span>,
      },
      {
        Header: 'ID',
        accessor: 'id',
        Cell: ({ cell: { row, value } }) => <Link to={`/subscriptions/${row.original.subscription_id}/events/${row.original.event_id}`}>{value}</Link>,
      },
      {
        accessor: 'status',
        Header: () => (
          <div
            style={{
              textAlign: "right"
            }}
          >Status</div>
        ),
        Cell: ({ cell: { value, row } }) => (
          <div
            style={{
              textAlign: "right"
            }}
          >
            <StatusBadge subscriptionEvent={row.original} />
          </div>
        ),
        disableSortBy: true,
      },
      // {
      //   // Build our options column
      //   id: 'options', // Make sure it has an ID
      //   // Cell: ({ cell: { row } }) => <SubscriptionOptionsDropdown />
      // },
    ],
    []
  )

  const data = React.useMemo(
    () => subscriptionEvents,
    [subscriptionEvents]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable<SubscriptionEvent>(
    { columns, data },
    useSortBy,
  )

  return (
    <div className="table-responsive">
     <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render('Header')}
                 <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()} className="list">
         {rows.map(row => {
           prepareRow(row)
           return (
             <>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                })}
              </tr>
            </>
           )
         })}
       </tbody>
     </table>
    </div>
  );
};
