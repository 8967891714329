import React from 'react';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { MembersList } from '../../components/MembersList/MembersList'


const OrganizationMembers: React.FC = () => {

  return (
    <>
      <PageHeader title="Members" sup_title="Organization" />
      <div className="row justify-content-between">
        <div className="col-12 col-md-12">
          <MembersList />
        </div>
      </div>
    </>
  );
}

export { OrganizationMembers };
