import React from 'react';
import { Route, useParams, useLocation, useRouteMatch, Link } from 'react-router-dom'

import { useSubscription, Subscription } from '../../resources/useSubscriptions';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { SubscriptionCard } from '../../components/SubscriptionCard/SubscriptionCard'

import { UnderConstruction } from '../../components/UnderConstruction'

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';
import { NotFound } from '../../components/NotFound';

import { Overview } from './Overview'
import { EventsList } from './EventsList'
import { SubscriptionEventCard } from './SubscriptionEventCard'

import Skeleton from 'react-loading-skeleton';

interface RouteParams {
  id: string
}

const SubscriptionDetailsPage: React.FC = () => {

  const params = useParams<RouteParams>();
  const location = useLocation();
  const { path, url } = useRouteMatch();

  const { isIdle, isLoading, isError, error, data: subscription } = useSubscription(params.id); // pull this out to container?

  const tabs = (
    <ul className="nav nav-tabs nav-overflow header-tabs">
      <li className="nav-item">
        <Link to={`/subscriptions/${params.id}/`} className={`nav-link ${location.pathname == `/subscriptions/${params.id}` || location.pathname == `/subscriptions/${params.id}/` ? "active" : ""}`} >
          Overview
        </Link>
      </li>
      <li className="nav-item">
        <Link to={`/subscriptions/${params.id}/events`} className={`nav-link ${location.pathname.startsWith(`/subscriptions/${params.id}/events`) ? "active" : ""}`} >
          Events
        </Link>
      </li>
    </ul>
  )
  return (
    <>
      <PageHeader title={ subscription ? `${subscription.name || subscription.url} (${subscription.id})`: <Skeleton width={200} />} sup_title="Subscription" tabs={tabs} />
      {isIdle || isLoading
        ? <Loading />
        : isError
          ? <Error />
          : !subscription
            ? <NotFound />
            : <>
                <Route exact path={`${path}`}>
                  <Overview />
                </Route>
                <Route exact path={`${path}/events`}>
                  <EventsList subscription={subscription}/>
                </Route>
                <Route exact path={`${path}/events/:id`}>
                  <SubscriptionEventCard subscription={subscription}/>
                </Route>
              </>
      }
    </>
  )

};

export { SubscriptionDetailsPage };
