import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider'

const RESOURCE_URL = '/dashboard/event-types';
const RESOURCE_CACHE_KEY = 'event-types';

export type EventType = string;

type ResultData = EventType[];

// simple axios async call
// export const useEventTypesQuery = async (queryKey: string): Promise<ResultData> => {
//   const { data } = await axios.get(RESOURCE_URL);
//   return data;
// };

// export const useEventTypes = () => useQuery<ResultData>(RESOURCE_CACHE_KEY, useEventTypesQuery);

export const getEventTypes = (client: AxiosInstance) => {
  return async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    const { data } = await client.get(RESOURCE_URL);
    return data;
  }
}

export const useEventTypes = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, getEventTypes(axios));
};
