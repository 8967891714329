import axios from 'axios'
import React from 'react';

import { Loading } from '../../components/Loading/Loading';

import { PageHeader } from '../../components/PageHeader/PageHeader';


const OrganizationBilling: React.FC = () => {

  const [billingUrl, setBillingUrl] = React.useState<string>()

  React.useEffect(() => {

    axios.post('/organizations/billing/session').then( (res) => {
      setBillingUrl(res.data.url)
    });
  }, [])

  const ExternalRedirect = ( { url }:{ url : string }) => {
    window.location.replace(url);
    return <></>
  }

  return (
    <>
      <PageHeader title="Billing" sup_title="Organization" />
      {billingUrl
        ? <ExternalRedirect url={billingUrl} />
        : <Loading />
      }
    </>
  );
}

export { OrganizationBilling };
