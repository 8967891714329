import React from 'react';
import { useParams, useLocation, useRouteMatch, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';

import { useEvent, Event } from '../../resources/useEvents';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { EventCard } from '../../components/EventCard/EventCard'

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';
import { NotFound } from '../../components/NotFound';

interface RouteParams {
  id: string
}

// type EventProps = {
//   event: Event
// }

interface RouteParams {
  id: string
}

const Overview: React.FC = () => {

  const params = useParams<RouteParams>();

  const { isIdle, isLoading, isError, error, data: event } = useEvent(params.id); // pull this out to container?
  return (
    <>
    {isIdle || isLoading
      ? <Loading />
      : isError  // Todo (nrmitchi): This way of "Error handling" is _horrid_
        ? error && error.message == "Request failed with status code 404"
          ? <NotFound />
          : <Error />
        : <EventCard event={event} />
    }
    </>
  )
}


const EventDetailsPage: React.FC = () => {

  const params = useParams<RouteParams>();

  const { isIdle, isLoading, isError, error, data: event } = useEvent(params.id); // pull this out to container?

  return (
    <>
      <PageHeader title={ event ? `${event.id}`: <Skeleton width={200} />} sup_title="Event" />
      <Overview />
    </>
  )

};

export { EventDetailsPage };
