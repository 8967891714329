import axios from 'axios'

import React, { useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';

export const useAccessToken = () => {
  const { getToken } = useAuth();

  const [token, setToken] = React.useState<string | null>('');

  useEffect(() => {
    (async () => {
      const accessToken = await getToken()
      setToken(accessToken);
    })();
  }, []);

  return { token, setToken };
};
