import React from 'react';

export const SuccessRateBadge = ({ value }: { value: string }) => {
    const v = parseFloat(value) * 100
    let color = "success"
  
    // The rate can be null. Display this differently
    if (isNaN(v)) {
        return (
            <span className={`badge bg-secondary-soft`}> ---- </span>
        )
    }
  
    if (v < 50) {
        color = "danger"
    } else if (v < 80) {
        color = "warning"
    } else if (v < 95) {
        color = "info"
    }
  
    return (
        <span className={`badge badge-${color}`}>{Math.round(v)}%</span>
    );
}
