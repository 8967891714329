import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider'

const RESOURCE_URL = '/organizations/members';
const RESOURCE_CACHE_KEY = 'members';

export type User = {
  id: number;
  user_id: string;
  user: {
    name: string;
    email: string;
    avatar?: string;
  }
  role: string;
  state: string; // Not currently provided, but will be added to represent the state of outstanding invitations
  created_at: string;
  updated_at: string;
  deleted_at: string;
};

type ResultData = User[];

// simple axios async call
// export const useMembersQuery = async (queryKey: string): Promise<ResultData> => {
//   const { data } = await axios.get(RESOURCE_URL);
//   return data;
// };

// export const useMembers = () => useQuery<ResultData>(RESOURCE_CACHE_KEY, useMembersQuery);

export const getMembers = (client: AxiosInstance) => {
  return async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    console.log(client)
    const { data } = await client.get(RESOURCE_URL);
    return data;
  }
}

export const useMembers = () => {
  // const { client } = useAxios();
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, getMembers(axios));
};
