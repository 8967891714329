import axios from 'axios'

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'

import { useMutation, useQueryClient } from 'react-query'
import { useForm, Controller } from 'react-hook-form';

import Select, { ValueType } from 'react-select'

import { useTable, Column, useSortBy } from 'react-table'

import { useToasts } from 'react-toast-notifications'

import { Modal, Form, Button } from 'react-bootstrap'

import { Subscription } from '../../resources/useSubscriptions';
import { useEventTypes, EventType } from '../../resources/useEventTypes';
import { useRealms, Realm } from '../../resources/useRealms';

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

import { SuccessRateBadge } from "../../components/Badges"


type Props = {
  subscriptions: Subscription[];
};

const SubscriptionsListTable: React.FC<Props> = ({ subscriptions }) => {

  const EventTypeKeys = ({ values }: { values: string[] }) => {
    // Loop through the array and create a badge-like component instead of a comma-separated string
    return (
      <>
        {values?.sort().map((k, idx) => {
          return (
            <div key={idx} className="badge badge-light" style={{ textAlign: 'left', margin: '5px' }}>
              {k}
            </div>
          );
        })}
      </>
    );
  };

  const SubscriptionOptionsDropdown = ({ value }: { value: string }) => {
    return (
      <div className="dropdown">
        <a
          className="dropdown-ellipses dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fe fe-more-vertical"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a href="#" className="dropdown-item">
            Option 1
          </a>
          <a href="#" className="dropdown-item">
            Option 2
          </a>
        </div>
      </div>
    );
  };

  const columns: Array<Column<Subscription>> = React.useMemo(
    () => [
      {
        // Build our selector/expander column
        id: 'selector', // Make sure it has an ID
      },
      // {
      //   Header: 'Name',
      //   id: 'name',
      //   accessor: (sub): string => sub.name || sub.id,
      //   Cell: ({ cell: { row, value } }) => <Link to={`/subscriptions/${row.original.id}`}>{value}</Link>,
      // },
      {
        Header: 'Realm',
        accessor: 'realm',
        Cell: ({ cell: { value } }) => <span className="badge badge-light" style={{ margin: '5px' }}>{value}</span>
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ cell: { row, value } }) => <Link to={`/subscriptions/${row.original.id}`}>{value}</Link>,
      },
      {
        Header: 'Keys',
        accessor: 'keys',
        Cell: ({ cell: { value } }) => <EventTypeKeys values={value} />,
        // getProps: (cell) => ({ id: () => alert("clicked")})
      },
      {
        accessor: 'success_rate',
        Header: () => (
          <div
            style={{
              textAlign: "right"
            }}
          >Success Rate</div>
        ),
        Cell: ({ cell: { value } }) => (
          <div
            style={{
              textAlign: "right"
            }}
          >
            <SuccessRateBadge value={value} />
          </div>
        ),
      },
      {
        // Build our options column
        id: 'options', // Make sure it has an ID
        // Cell: ({ cell: { row } }) => <SubscriptionOptionsDropdown />
      },
    ],
    []
  )

  const data = React.useMemo(
    () => subscriptions,
    [subscriptions]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Subscription>(
    { columns, data },
    useSortBy
  )

  return (
    <div className="table-responsive">
     <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render('Header')}
                 <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()} className="list">
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               {row.cells.map(cell => {
                 return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
    </div>
  );
};

const SubscriptionsListSearch = () => (
  <form>
    <div className="input-group input-group-flush">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="fe fe-search"></i>
        </span>
      </div>
      <input className="list-search form-control" type="search" placeholder="Search" />
    </div>
  </form>
);

const SubscriptionsListEmpty = () => {
  return (
    <div className="row">
      <div className="col-12">
        {/* <!-- Card --> */}
        <div className="card card-inactive">
          <div className="card-body text-center">
            {/* <!-- Image --> */}
            <img src="/assets/img/illustrations/scale.svg" alt="..." className="img-fluid" style={{ maxWidth: '182px' }} />

            {/* <!-- Title --> */}
            <h1>No Subscription yet.</h1>
            {/* <!-- Subtitle --> */}
            <p className="text-muted">Create a Subscription from your app with our API, or manually below.</p>

            <CreateSubscriptionModal />
          </div>
        </div>
      </div>
    </div>
  )
};

const SubscriptionsList: React.FC<Props> = ({ subscriptions }) => {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="subscriptionsListPane"
        role="tabpanel"
        aria-labelledby="subscriptionsListTab"
      >
        <div
          className="card"
          id="subscriptionsList"
        >
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <SubscriptionsListSearch />
              </div>
              <div className="col-auto">
                <CreateSubscriptionModal />
              </div>
            </div>
          </div>

          <SubscriptionsListTable subscriptions={subscriptions} />
        </div>
      </div>
    </>
  );
};

const CreateSubscriptionModal = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const { addToast } = useToasts()

  const eventTypes = useEventTypes(); // pull this out to container?
  const realms = useRealms(); // pull this out to container?

  type FormValues = {
    name: string;
    description: string;
    realm: string;
    url: string;
    keys: string[];
  };
  const { register, handleSubmit, formState, control } = useForm<FormValues>();
  const { errors } = formState

  const queryClient = useQueryClient()

  const createSubscription = useMutation( ({ name, description, realm, url, keys }: FormValues) => {
    return axios.post("/subscriptions", {
      name: name,
      description: description,
      realm: realm,
      keys: keys,
      url: url,
    })
    .then( res => {
      addToast(`Subscription Created: ${res.data.id}`, {
        appearance: 'success',
        autoDismiss: true,
      })
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      addToast(`Subscription Creation Failed`, {
        appearance: 'error',
        autoDismiss: true,
      })
    });
  }, {
    onSuccess: () => {
      console.log("invaliding subscriptions cache")
      queryClient.invalidateQueries('subscriptions')
      queryClient.refetchQueries('subscriptions')
      console.log("refetched subscriptions")
    }
  })

  const onSubmit = ( (data: FormValues) => {
    createSubscription.mutateAsync(data).then( (res) => {
      // Todo: Only want this on mutate success
      setModalShow(false)
    })
  })

  const isUrl = ( (value: string) => {
    return value.startsWith("http")
  })

  const isHttps = ( (value: string) => {
    return value.startsWith("https://")
  })

  // This is explicitly for react-select to play nicely (ish) with Typescript
  type OptionType = {label: string, value: string}

  return (
    <>
    <button className="btn btn-sm btn-white" onClick={() => setModalShow(true)}>Create Subscription</button>

    <Modal
      show={modalShow} onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Subscription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Endpoint</Form.Label>
            <Form.Control type="text" placeholder="url" name="url" className={`${errors.url ? 'is-invalid' : ''}`} ref={register({required: true, maxLength: 180, validate: {
              isUrl: value => isUrl(value),
              isHttps: value => isHttps(value),
            }})} />
            {errors.url && errors.url.type === 'isUrl' && (
              <small className="text-danger">Your url is invalid</small>
            )}
            {errors.url && errors.url.type === 'isHttps' && (
              <small className="text-danger">URL must be https.</small>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="name (optional)" name="name" ref={register({maxLength: 80})} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="description (optional)" name="description" ref={register({maxLength: 200})} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Realm</Form.Label>
            {realms.isIdle || realms.isLoading
              ? <Loading />
              : realms.isError
                ? <Error />
                : <Controller
                    control={control}
                    name="realm"
                    rules={{required: true}}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    render={( { onChange } ) => (
                      <Select
                        onChange={(selectedOption: ValueType<OptionType, boolean>) => {
                          const value = (selectedOption as OptionType).value;

                          onChange(value)
                        }}
                        options={realms.data.sort().map((r: Realm) => {
                          return { value: r, label: r }
                        })}
                      />
                    )}
                  />
            }
            {errors.realm && errors.realm.type && (
              <small className="text-danger">{errors.realm.message || 'Realm is required.'}</small>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>Events</Form.Label>
            {eventTypes.isIdle || eventTypes.isLoading
              ? <Loading />
              : eventTypes.isError
                ? <Error />
                : <Controller
                    control={control}
                    name="keys"
                    rules={{required: true, minLength: 1}}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    render={( { onChange } ) => (
                      <Select
                        isMulti
                        onChange={(selectedOption: ValueType<OptionType, boolean>) => {
                          const values = (selectedOption as OptionType[]).map((o) => o.value);
                          onChange(values)
                        }}
                        options={eventTypes.data.sort().map((r: EventType) => {
                          return { value: r, label: r }
                        })}
                      />
                    )}
                  />
            }
            {errors.keys && (<small className="text-danger">At least one event type is required.</small> )}
          </Form.Group>

          <input className="btn btn-primary" type="submit" />
        </Form>
      </Modal.Body>
    </Modal>
    </>
  );
}

export { SubscriptionsList, SubscriptionsListEmpty };
