
import axios from 'axios'
import React from 'react';
import { Route, useParams, useLocation, useRouteMatch, Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

import { useTable, Column, useSortBy, useExpanded, CellProps } from 'react-table'

import { useToasts } from 'react-toast-notifications'

import ReactJson from 'react-json-view'

import { UnderConstruction } from '../../components/UnderConstruction'

import { Subscription } from '../../resources/useSubscriptions';
import { SubscriptionEvent, useSubscriptionEvent, WebhookAttempt } from '../../resources/useSubscriptionEvents';

import { NotFound } from '../../components/NotFound'
import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

import Skeleton from 'react-loading-skeleton';


type Props = {
  subscription: Subscription
}
type AttemptTableProps = {
  attempts: WebhookAttempt[]
}
interface RouteParams {
  id: string
}


export const SubscriptionEventCard: React.FC<Props> = ({ subscription }) => {

  const params = useParams<RouteParams>();

  const { addToast } = useToasts()
  const queryClient = useQueryClient()
  const { isIdle, isLoading, isError, error, data: subscriptionEvent } = useSubscriptionEvent(subscription.id, params.id); // pull this out to container?

  const StatusBadge = ( { subscriptionEvent }: { subscriptionEvent: SubscriptionEvent } ) => {

    let color = 'info'

    if (subscriptionEvent.status == 'success') {
      color = 'success'
    } else if (subscriptionEvent.status == 'failed') {
      color = 'danger'
    }
    return (
      <span className={`badge badge-${color}`}>
        {subscriptionEvent.status}
      </span>
    );
  }

  const redeliverSubscriptionEvent = useMutation( ({ subscription_id, event_id }: { subscription_id: string, event_id: string }) => {
    return axios.post(`/subscriptions/${subscription_id}/events/${event_id}/retry`)
      .then( res => {
        addToast(`Event Delivery Queued: ${res.data.id}`, {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        addToast(`Event Delivery queuing Failed`, {
          appearance: 'error',
          autoDismiss: true,
        })
      });
    }, {
    onSuccess: () => {
      console.log("invaliding subscriptions cache")
    }
  })

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-header-title">
                Details
              </h4>
            </div>
            <div className="card-body">

              <div className="mb-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Subscription</small> <small>{subscriptionEvent ? <Link to={`/subscriptions/${subscriptionEvent.subscription_id}`}>{subscriptionEvent.subscription?.name ? subscriptionEvent.subscription.name : subscriptionEvent.subscription_id}</Link> : <Skeleton width={100} />}</small>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Event</small> <small>{subscriptionEvent ? <Link to={`/events/${subscriptionEvent.event_id}`}>{subscriptionEvent.event_id}</Link> : <Skeleton width={100} />}</small>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Type</small> {subscriptionEvent ? <span className="badge badge-light">{subscriptionEvent.event.key}</span> : <Skeleton width={100} />}
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Realm</small> {subscriptionEvent ? <span className="badge badge-light">{subscriptionEvent.event.realm}</span> : <Skeleton width={100} />}
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Status</small> {subscriptionEvent ? <StatusBadge subscriptionEvent={subscriptionEvent} /> : <Skeleton width={100} />}
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Attempts Made</small> {isIdle || isLoading ? <Skeleton width={30} /> : <small>{subscriptionEvent?.attempts ? subscriptionEvent.attempts.length : '0'}</small>}
                  </li>
                </ul>
              </div>

            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                Payload
              </h4>
            </div>
            <div className="card-body">
              {isLoading
                ? <Loading />
                : isError
                  ? <Error />
                  : subscriptionEvent
                    ? <ReactJson src={subscriptionEvent.event.payload} />
                    : <NotFound />
              }
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex">
              <h4 className="card-header-title">
                Attempts {subscriptionEvent?.next_attempt_at ? <span className="small text-muted" style={{ marginLeft: '15px'}}><i>Next attempt at: {subscriptionEvent.next_attempt_at}</i></span> : subscriptionEvent?.attempts?.length == 0 && <span className="small text-muted" style={{ marginLeft: '15px'}}><i>Initial attempt queued...</i></span>}
              </h4>
              {isLoading || subscriptionEvent?.status == "pending" || subscriptionEvent &&
                <button
                  className="btn btn-sm btn-white"
                  type="button"
                  onClick={() => redeliverSubscriptionEvent.mutate({ subscription_id: subscriptionEvent.subscription_id, event_id: subscriptionEvent.event_id }) }
                >
                  <i className="fe fe-sliders mr-1"></i> Redeliver
                </button>
              }
            </div>

            {isIdle || isLoading
              ? <Loading />
              : isError
                ? <Error />
                : !subscriptionEvent
                  ? <NotFound />
                  : !subscriptionEvent?.attempts || subscriptionEvent?.attempts?.length == 0
                    ? <NotFound />
                    : <SubscriptionEventAttemptsTable attempts={subscriptionEvent.attempts} />
            }
          </div>

        </div>
      </div>
    </>
  )
}

const ResponseCodeAsBadge = ({ value }: { value: string }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  const i = parseInt(value)

  let badge = 'danger'

  if (i < 200) {
    badge = 'danger'
    value = 'error'
  } else if (i >= 200 && i < 300) {
    badge = 'success'
  } else if (i < 400) {
    badge = 'warning'
  }

  return (
    <span className={`badge badge-${badge}`} style={{ margin: '5px' }}>{value}</span>
  );
};

const SubscriptionEventAttemptRequestData: React.FC<{attempt: WebhookAttempt}> = ({ attempt }) => {

  const [openTab, setOpenTab] = React.useState<string>('request')

  // const handleTabChange = ({ tab }: { tab: string }) => {
  //   return (e) => {
  //     e.preventDefault();
  //     setOpenTab(tab);
  //   }
  // }

  const hideStyle = {
    display: 'none'
  }

  return (
    <>
      <ul className="nav nav-tabs nav-tabs-sm">
        <li className="nav-item">
          <a href="#" onClick={(e) => { e.preventDefault(); setOpenTab('request')}} className={`nav-link ${openTab == 'request' && 'active'}`}>
            Request
          </a>
        </li>
        <li className="nav-item">
          <a href="#" onClick={(e) => { e.preventDefault(); setOpenTab('response')}} className={`nav-link ${openTab == 'response' && 'active'}`}>
            Response
          </a>
        </li>
      </ul>

      <div style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
        { openTab == 'request'
            ? <div className={`tab-pane`} style={openTab != 'request' ? hideStyle : {}} id="request" role="tabpanel" aria-labelledby="request-tab">
                <h3>Headers</h3>
                <pre style={{ padding: '0.5rem',  width: '100%', overflowX: 'scroll' }}>
                {attempt.request_headers && Object.keys(attempt.request_headers).map( (header) => {
                  return (<div key={header}><strong>{header}:</strong> {attempt.request_headers[header]}</div>)
                })}
                </pre>
              </div>
            : <div className={`tab-pane`} style={openTab != 'response' ? hideStyle : {}} id="response" role="tabpanel" aria-labelledby="response-tab">
                <h3>Headers</h3>
                <pre style={{ padding: '0.5rem',  width: '100%', overflowX: 'scroll' }}>
                {attempt.response_headers && Object.keys(attempt.response_headers).map( (header) => {
                  return (<div key={header}><strong>{header}:</strong> {attempt.response_headers[header]}</div>)
                })}
                </pre>
                <h3>Body</h3>
                {attempt.response_body_json ? <ReactJson src={attempt.response_body_json} /> : <pre style={{ padding: '0.5rem' }}>{attempt.response_body || <i>No Body Returned</i>}</pre> }
              </div>
          }
      </div>
    </>
  )
}

const RightArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
  </svg>
)
const DownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
  </svg>
)

const SubscriptionEventAttemptsTable: React.FC<AttemptTableProps> = ({ attempts }) => {

  const columns: Array<Column<WebhookAttempt>> = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ cell: { row } }: CellProps<WebhookAttempt, string>) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <DownArrow /> : <RightArrow />}
          </span>
        ),
      },
      {
        Header: 'Timestamp',
        accessor: 'created_at',
        // Cell: ({ cell: { value } }) => <SuccessRateBadge value={value} />,
      },
      {
        Header: 'Response Code',
        accessor: 'status_code',
        Cell: ({ cell: { value } }) => <ResponseCodeAsBadge value={value} />,
      },
      // {
      //   // Build our options column
      //   id: 'options', // Make sure it has an ID
      //   // Cell: ({ cell: { row } }) => <SubscriptionOptionsDropdown />
      // },
    ],
    []
  )

  const data = React.useMemo(
    () => attempts,
    [attempts]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable<WebhookAttempt>(
    { columns, data },
    useSortBy,
    useExpanded,
  )

  return (
    <div className="table-responsive">
     <table {...getTableProps()} className="table table-sm table-hover table-nowrap card-table">
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                 {column.render('Header')}
                 <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()} className="list">
         {rows.map(row => {
           prepareRow(row)
           return (
             <>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (<td {...cell.getCellProps()}>{cell.render('Cell')}</td>)
                })}
              </tr>
              {/*
                  If the row is in an expanded state, render a row with a
                  column that fills the entire length of the table.
                */}
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length} style={{ padding: '0' }}>
                    {/*
                      Inside it, call our renderRowSubComponent function. In reality,
                      you could pass whatever you want as props to
                      a component like this, including the entire
                      table instance. But for this example, we'll just
                      pass the row
                    */}
                    <SubscriptionEventAttemptRequestData attempt={row.original} />
                  </td>
                </tr>
              ) : null}
            </>
           )
         })}
       </tbody>
     </table>
    </div>
  );
};
