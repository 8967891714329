import axios from 'axios';

import React, { useState, Fragment } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useMutation } from 'react-query'

import { User, useMembers } from '../../resources/useMembers';

import { Loading } from '../Loading/Loading';
import { Error } from '../Error/Error';


const AddMemberDropdown: React.FC = () => {

  // Todo (nrmitchi): If we support Github Enterprise, this will have to be configurable
  const GITHUB_SEARCH_URI = 'https://api.github.com/search/users';

  const [newUsername, setNewUsername] = useState<string>()
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query: string) => {
    setIsLoading(true);

    fetch(`${GITHUB_SEARCH_URI}?q=${query}`)
      .then((resp) => resp.json())
      .then(({ items }) => {
        // const options = items.map((i: any) => ({
        //   avatar_url: i.avatar_url,
        //   id: i.id,
        //   login: i.login,
        // }));
        const options = items.map((i: any) => (i.login));

        setOptions(options);
        setIsLoading(false);
      });
  };

  const addMember = useMutation( ({ username }: {username: string}) => {
    return axios.post("/organizations/members", {
      name: username
    });
  })

  const handleUsernameChange = ((value: string[]) => {
    setNewUsername(value[0])
  })

  const onSubmit = ( (event: any) => {
    event.preventDefault()

    if (!newUsername) {
      console.log("Ignoring form submission due to validation error")
      return
    }

    addMember.mutate({username: newUsername})
  })

  return (
    <div className="dropdown">
      <button className="btn btn-sm btn-primary" type="button" id="addMember" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Invite member</button>
      <form className="dropdown-menu dropdown-menu-right dropdown-menu-card" aria-labelledby="addMember" onSubmit={onSubmit}>
        <div className="card-header">
          <h4 className="card-header-title">Invite a member</h4>
          <span className="badge badge-soft-primary">Unlimited Seats!</span>
        </div>
        <div className="card-header">
          <div className="row no-gutters align-items-center">
            <div className="col-3">
              <label className="mb-0" htmlFor="addMemberUsername">Email</label>
            </div>
            <div className="col">
              <AsyncTypeahead
                id="github-username-search"
                isLoading={isLoading}
                minLength={3}
                onSearch={handleSearch}
                onChange={handleUsernameChange}
                options={options}
                placeholder="Search for a Github user..."
                renderMenuItemChildren={(option: any, props: any) => (
                  <Fragment>
                    <img
                      alt={option}
                      src={`https://www.github.com/${option}.png`}
                      style={{
                        height: '24px',
                        marginRight: '10px',
                        width: '24px',
                      }}
                    />
                    <span>{option}</span>
                  </Fragment>
                )}
              />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button className="btn btn-block btn-primary" type="submit">Add member</button>
        </div>
      </form>
    </div>
  )
}

const MembersList: React.FC = () => {
  const { isIdle, isLoading, isError, data: users = [] } = useMembers(); // pull this out to container?

  const removeUser = useMutation( ({ member }: {member: User}) => {
    return axios.delete(`/organizations/members/${member.id}`, {});
  })

  const handleMemberRemoval = ((member: User) => {
    return ((event: any) => {
      event.preventDefault()
      removeUser.mutate({member: member})
    })
  })

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Members <span className="badge badge-soft-info ml-2">Beta</span></h4>
        <AddMemberDropdown />
      </div>

      <div className="card-body">
        <div className="list-group list-group-flush my-n3">
        {isIdle || isLoading
          ? <Loading />
          : isError
            ? <Error />
            : users.map((u: User) => {
              return (
                <div className="list-group-item" key={u.id}>
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <span className="avatar">
                        {u.user.avatar
                          ? <img src={u.user.avatar} alt={`${u.user.name} Profile Image`} className="avatar-img rounded-circle" />
                          : <span className="avatar-title rounded-circle">{u.user.name.charAt(0).toUpperCase()}</span>
                        }
                      </span>
                    </div>
                    <div className="col-5 ml-n2">
                      <h4 className="mb-1">
                        <span>{u.user.name}</span> { u.state == "pending" && <span className="badge badge-soft-secondary ml-2">pending</span> }
                      </h4>
                      <p className="small text-muted mb-0">
                        <span className="d-block text-reset text-truncate">{u.user.email}</span>
                      </p>
                    </div>
                    <div className="col-auto ml-auto mr-n3">
                      <select className="custom-select custom-select-sm" data-toggle="select">
                        <option value="admin" selected={ u.role === 'admin'}>Admin</option>
                        <option value="member" selected={ u.role === 'member'}>Member</option>
                      </select>
                    </div>
                    <div className="col-auto">
                      <div className="dropdown">
                        <a className="dropdown-ellipses dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fe fe-more-vertical"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a className="dropdown-item" onClick={handleMemberRemoval(u)}>
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
        }
        </div>
      </div>
    </div>
  );
};

export { MembersList };
