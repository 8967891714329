import React from 'react';

import { useEvents } from '../../resources/useEvents';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { EventsList } from '../../components/EventsList/EventsList';

import { EventsListEmpty } from '../../components/EventsList/EventsList';
import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';

const Events: React.FC = () => {
  const { isIdle, isLoading, isError, data: events = [] } = useEvents(); // pull this out to container?

  return (
    <>
      <PageHeader title="Events" />
      {isIdle || isLoading
        ? <Loading />
        : isError
          ? <Error />
          : !events || events.length === 0
            ? <EventsListEmpty />
            : <EventsList events={events} />
      }
    </>
  )

};

export { Events };
