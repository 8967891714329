import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider';
import { Event } from './useEvents'
import { Subscription } from './useSubscriptions'

const RESOURCE_URL = '/events';
const RESOURCE_CACHE_KEY = 'subscription-events';

export type WebhookAttempt = {
  id: string;
  status_code: string;
  request_headers: Record<string, string>;
  response_headers: Record<string, string>;
  response_body: string;
  response_body_json: Record<string, unknown>;
  // response_time: string;
  created_at: string;
}

export type SubscriptionEvent = {
  id: string;
  subscription_id: string;
  subscription: Subscription;
  event_id: string;
  event: Event;
  attempts?: WebhookAttempt[];
  status: string;
  next_attempt_at: string;
  created_at: string;
};

type ResultData = SubscriptionEvent[];

const TEST_DATA = [
  {
    'id': 'ksuidsub1evt1',
    'subscription_id': 'ksuidsub1',
    'event_id': 'ksuidevent1',
    'created_at': '2020-10-10',
  },
  {
    'id': 'ksuidsub2evt2',
    'subscription_id': 'ksuidsub2',
    'event_id': 'ksuidevent2',
    'created_at': '2020-10-10',
  }
]

export const useSubscriptionEvent = (subscription_id: string, event_id: string) => {
  return useQuery<SubscriptionEvent>([RESOURCE_CACHE_KEY, subscription_id, event_id], async (queryKey: QueryFunctionContext): Promise<SubscriptionEvent> => {
    const { data } = await axios.get(`/subscriptions/${subscription_id}/events/${event_id}`);
    return data;
  });
};

export const useSubscriptionEventsFromSubscription = (subscription_id: string) => {
  return useQuery<ResultData>([`${RESOURCE_CACHE_KEY}-sub`, subscription_id], async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    const { data } = await axios.get(`/subscriptions/${subscription_id}/events`);
    return data;
  });
};

export const useSubscriptionEventsFromEvent = (event_id: string) => {
  return useQuery<ResultData>([`${RESOURCE_CACHE_KEY}-event`, event_id], async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    const { data } = await axios.get(`/events/${event_id}/subscriptions`);
    return data;
  });
};
