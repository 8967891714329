
import React from 'react';
import { useParams } from 'react-router-dom'

import { useSubscription, Subscription } from '../../resources/useSubscriptions';

import { Loading } from '../../components/Loading/Loading';
import { Error } from '../../components/Error/Error';
import { NotFound } from '../../components/NotFound';

import { SubscriptionCard } from '../../components/SubscriptionCard/SubscriptionCard'

interface RouteParams {
  id: string
}

export const Overview: React.FC = () => {

  const params = useParams<RouteParams>();

  const { isIdle, isLoading, isError, error, data: subscription } = useSubscription(params.id); // pull this out to container?
  return (
    <>
    {isIdle || isLoading
      ? <Loading />
      : isError  // Todo (nrmitchi): This way of "Error handling" is _horrid_
        ? error && error.message == "Request failed with status code 404"
          ? <NotFound />
          : <Error />
        : <SubscriptionCard subscription={subscription} />
    }
    </>
  )
}
