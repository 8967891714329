import axios, { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query'

import { useQuery } from './useQuery';
import { useAxios } from '../providers/AxiosProvider';

const RESOURCE_URL = '/organizations';
const RESOURCE_CACHE_KEY = 'organizations';

export type Organization = {
  id: string;
  name: string;
  avatar?: string;
  role: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
};

type ResultData = Organization[];

export const useOrganizations = () => {
  return useQuery<ResultData>(RESOURCE_CACHE_KEY, async (queryKey: QueryFunctionContext): Promise<ResultData> => {
    // const { client } = useAxios();
    // console.log(client)
    const { data } = await axios.get(RESOURCE_URL);
    return data;
  });
};
