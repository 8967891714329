
import axios from 'axios'
import React from 'react';

import { NotFound } from '../NotFound'

import { Subscription } from '../../resources/useSubscriptions';

import FileDownload from 'js-file-download'
import Skeleton from 'react-loading-skeleton';

import { SuccessRateBadge } from "../../components/Badges"

type Props = {
  subscription?: Subscription;
};

function getSubscriptionStatus(s: Subscription) {

  let color = "success"
  let t = "Enabled"

  if (!s.verified) {
    color = "secondary"
    t = "Unverified"
  } else if (!s.enabled) {
    color = "danger"
    t = "Disabled"
  }

  return <span className={`badge badge-${color}`}>{t}</span>
}

const SubscriptionCard: React.FC<Props> = ({ subscription }) => {

  if (!subscription) {
    return <NotFound />
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card card-fill">
            <div className="card-header d-flex">
              <h4 className="card-header-title">
                Webhook Details
              </h4>
              <button
                className="btn btn-sm btn-white"
                type="button"
              >
                <i className="fe fe-sliders mr-1"></i> Edit <span className="badge badge-primary ml-1 d-none">0</span>
              </button>
            </div>
            <div className="card-body">

              <div className="mb-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>URL</small> <small>{subscription.url}</small>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Status</small> {getSubscriptionStatus(subscription)}
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Description</small> <small>{subscription.description}</small>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Realm</small> <small><span className="badge badge-light" style={{ margin: '5px' }}>{subscription.realm}</span></small>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Event Types</small>
                    <div>{subscription.keys?.map((k) => {
                      return <span key={k} className="badge badge-light" style={{ margin: '5px' }}>{k}</span>
                    })}</div>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-between px-0">
                    <small>Success Rate</small> <small><SuccessRateBadge value={subscription.success_rate} /></small>
                  </li>
                </ul>
              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  );
};


export { SubscriptionCard };
